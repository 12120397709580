export default [
    {
        title: 'Menu_Studio.Back To Dashboard',
        route: 'channels',
        icon: 'HomeIcon',
        id: 'backToDashboard'
    },
    {
        title: 'Menu_Studio.Dashboard',
        route: 'studioDashboard',
        icon: 'GlobeIcon',
        id: 'dashboard'
    },
    {
        title: 'Menu_Studio.Content',
        route: 'videos',
        icon: 'FilmIcon',
        id: 'content'
    },
    {
        title: 'Menu_Studio.Playlists',
        route: 'playlists',
        icon: 'ListIcon',
        id: 'playlists'
    },
    // {
    //     title: 'Menu_Studio.Health Report',
    //     route: 'healthReport',
    //     icon: 'ActivityIcon',
    //     id: 'healthReport'
    // },
    {
        title: 'Menu_Studio.Channel Backup',
        route: 'channelBackup',
        icon: 'ShieldIcon',
        id: 'backup'
    },
    {
        title: 'Menu.Settings',
        icon: 'SettingsIcon',
        children: [
            {
                title: 'Menu_Studio.Default Upload Profile',//Menu_Studio.Default Upload Profile
                route: 'defaultUploadProfile',
                icon: 'ListIcon',
                child: true
            }
        ]
    },

    //  {
    //   title: 'Analytics',
    //   route: 'analytics',
    //   icon: 'PieChartIcon',
    //   id: 'analytics'
    // },

    {
        title: 'Menu_Studio.Comments',
        route: 'comments',
        icon: 'MessageSquareIcon',
        id: 'comments'
    }

]